<template>
  <page-container>
    <div class="layout">
      <div style="width: 100%; display: flex; align-items: center">
        <el-form
          :inline="true"
          :model="searchModel"
          style="margin-top: 20px; padding-left: 20px"
          size="mini"
        >
          <el-form-item label="所属区域:">
            <el-cascader
              ref="myCascader"
              v-model="selectedOptions"
              clearable
              placeholder="请选择区域"
              :options="regionOptions"
              :props="regionProps"
              @change="handleItemChange"
              filterable></el-cascader>
          </el-form-item>
          <el-form-item label="企业名称:" prop="compName">
            <el-input v-model="searchModel.compName"></el-input>
          </el-form-item>
          <el-form-item label="时间:">
            <el-date-picker
              v-model="searchModel.lecDate"
              type="year"
              placeholder="选择年月"
              style="width: 180px"
              value-format="yyyy-MM"
            >
            </el-date-picker>
          </el-form-item>
          <!--        <el-form-item label="报表状态:">
                  <el-select
                      v-model="searchModel.compPId"
                      placeholder="请选择所属企业"
                      filterable
                      clearable
                      style="width: 200px"
                  >
                    <el-option
                        v-for="item in companyList"
                        :key="item.compId"
                        :label="item.compName"
                        :value="item.compId"
                        v-if="item.compId!=compEditModel.compId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>-->
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="submitSearch"
              :loading="loading"
            >{{ searchText }}
            </el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <resize-wrap class="flex-1">
        <BaseTable
          id="noSubmitYear"
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :align="allAlign"
          :data="tableData"
          :cell-style="cellStyle"
          :seq-config="{startIndex: (tablePage.currentPage - 1) * tablePage.pageSize}"
        >
          <vxe-table-column type="seq" title="序号" width="60"/>
          <vxe-table-column
            field="compName"
            title="企业名称"
            show-overflow="title"
            min-width="180"
            show-header-overflow="title"/>
          <vxe-table-column
            field="compNumber"
            title="企业编号"
            show-overflow="title"
            min-width="100"
            show-header-overflow="title"/>
          <vxe-table-column
            field="areaName"
            title="所属区域"
            show-overflow="title"
            min-width="100"
            show-header-overflow="title"/>
          <vxe-table-column
            field="countPerson"
            title="统计员"
            show-overflow="title"
            min-width="100"
            show-header-overflow="title"/>
          <vxe-table-column
            field="countPhone"
            title="联系电话"
            show-overflow="title"
            min-width="100"
            show-header-overflow="title"/>
          <vxe-table-column
            field="address"
            title="企业地址"
            show-overflow="title"
            min-width="200"
            show-header-overflow="title"/>
          <vxe-table-column
            field="lecDate"
            title="报告年份"
            show-overflow="title"
            min-width="150"
            show-header-overflow="title"/>
          <vxe-table-column
            field="status"
            title="是否提交"
            show-overflow="title"
            min-width="100"
            :formatter="({cellValue, row, column }) => { return cellValue=='1'?'未提交':'已提交' }"
            show-header-overflow="title"/>
        </BaseTable>
      </resize-wrap>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="['Sizes','PrevPage','JumpNumber','NextPage','FullJump','Total']"
        @page-change="handlePageChange"
      />

    </div>
  </page-container>
</template>

<script>
import { getNoSubmitUpleReportList } from '@/apis/dataReport'
import { gainRegi } from '@/apis/common'
import moment from 'moment'

export default {
  name: 'noSubmitYear',
  data () {
    return {
      loading: false,
      tableData: [],
      searchModel: {
        compName: '',
        lecDate: moment().format('YYYY-MM')
      },
      searchText: '查询',
      allAlign: 'center',
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: ['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total'],
        perfect: true
      },
      regionProps: {
        // multiple: true,
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          const level = node.level
          if (level === 1) {
            resolve(node.children[0].label == '直辖区' ? [] : node.children)
            // resolve(node.children)
          }
          if (level === 2 && node.data.code) {
            if (node.children && node.children.lenght > 0) {
              resolve(node.children)
            } else {
              setTimeout(() => {
                gainRegi({
                  isMapJson: false,
                  regiLevel: node.level,
                  regiCodes: [node.data.code]
                }).then(res => {
                  if (res.code == 200) {
                    const nodeArr = res.data[0].regiCountrs.map(val => ({
                      value: val.regiCountrId,
                      label: val.countrName,
                      code: val.countrCode,
                      leaf: true
                    })
                    )
                    /*     nodeArr.unshift({
                           value:val.regiProvinId,
                           label:'全市',
                           code:val.provinCode,
                           leaf:true,
                         }) */
                    resolve(nodeArr)
                  }
                })
              })
            }
          } else if (level == 3) {
            resolve([])
          }
        }
      },
      regionOptions: [],
      selectedOptions: []
    }
  },
  methods: {
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    cellStyle ({ row, rowIndex, column, columnIndex }) {
      if (column.property === 'status') {
        if (row.status == 1) {
          return {
            color: 'red'
          }
        } else {
          return {
            color: 'green'
          }
        }
      }
    },
    handleItemChange (val) {},
    refreshData () { // todaymissing,alert单独处理
      this.loading = true
      getNoSubmitUpleReportList({
        ...this.searchModel,
        countrId: this.selectedOptions[2] ? this.selectedOptions[2] : undefined,
        cityId: this.selectedOptions[1] ? this.selectedOptions[1] : undefined,
        provinceId: this.selectedOptions[0] ? this.selectedOptions[0] : undefined,
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
        dataType: 3, // 1、未提交日报表，2、未提交月报表，3、未提交年报表
        type: 1 // 商砼
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          const data = res.data
          this.tableData = data.rows
          this.tablePage.total = data.totalSize
        } else {
          this.tableData = []
          this.tablePage.total = 0
        }
      }).catch(error => {
        console.log(error)
        //  this.logining = false;
      })
    },
    submitSearch () {
      this.loading = true
      this.tableData = []
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    stopSearch () {
      this.loading = false
      this.searchText = '查询'
    }
  },
  mounted () {
    // 获取区域数据
    gainRegi({
      regiLevel: 1,
      isMapJson: false,
      regiDto: {}
    }).then(res => {
      if (res.code == 200) {
        const regionOptions = res.data.map(val => ({
          value: val.regiProvinId,
          label: val.provinName,
          code: val.provinCode,
          children: val.regiCitys.map(valCity => ({
            value: valCity.regiCityId,
            label: valCity.cityName,
            code: valCity.cityCode,
            children: []
          }))
        }))
        this.regionOptions = regionOptions
      }
    }).catch(val => {
      console.log(val)
    })
    this.refreshData()
  }
}
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-color: white;
}
</style>
